/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public showMenu: any = true;


  public baseUrl: string = "https://api.aicasindia.in/api/";
  // public baseUrl = 'http://localhost:2222/api/';



  // eslint-disable-next-line @typescript-eslint/naming-convention
  ColumnMode = ColumnMode;
  constructor(
    private afs: AngularFirestore,
    public http: HttpClient
  ) {

  }

  //! ////////////////////////////////////////////////////////////////////////////////////
  //! Franchises api
  registerFranchises(data) {
    return this.http.post(this.baseUrl + 'franchises/register', data);
  }
  loginFranchises(data) {
    return this.http.post(this.baseUrl + 'franchises/login', data);
  }
  editFranchises(data) {
    return this.http.post(this.baseUrl + 'franchises/update', data);
  }

  //add materials api

  addMaterials(data) {
    return this.http.post(this.baseUrl + 'materials/add_materials', data);
  }
  getMaterials(data) {
    return this.http.post(this.baseUrl + 'materials/get_materials', data);
  }
  deleteMaterials(data) {
    return this.http.post(this.baseUrl + 'materials/delete_material', data);
  }
  //!student api
  registerStudents(data) {
    return this.http.post(this.baseUrl + 'students/register', data);
  }

  getAllStudentsByFranchises(data, pageNo) {
    // console.log(franchises_id)
    return this.http.post(this.baseUrl + 'students/getStudents_byFranchises/?page=' + pageNo, data);
  }

  editStudent(data) {
    return this.http.post(this.baseUrl + 'students/update', data);
  }

  deleteStudent(data) {
    return this.http.post(this.baseUrl + 'students/delete', data);
  }
  getStudentsByFranchiseExamTrue(data) {
    return this.http.post(this.baseUrl + 'students/getStudents_byFranchises_examAttended', data);
  }
  getPracticalMark(data) {
    return this.http.post(this.baseUrl + 'answers/generate_practical_mark', data);
  }
  // eslint-disable-next-line @typescript-eslint/naming-convention
  getResult(student_id) {
    return this.http.post(this.baseUrl + 'results/get_result', student_id);
  }
  updateResult(data) {
    return this.http.post(this.baseUrl + 'results/update_result', data);
  }
  getAllStudentsByFranchisesWithExamDetail(data) {
    return this.http.post(this.baseUrl + 'students/getStudents_byFranchises_withExamDetail', data);
  }
  //!dashboard api

  getLength(data) {
    return this.http.post(this.baseUrl + 'students/get_partner_dashboard', data);
  }

  getOneStudentWithExamDetail(data) {
    return this.http.post(this.baseUrl + 'students/getStudent_byFranchises_withExamDetail', data);

  }

  //!student id
  getStudentId() {
    return this.http.get(this.baseUrl + 'students/get_last_student');

  }
  //!New(null) students pagination
  newStudents(franchises_id: string, page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/pagination_getNew_students?franchises_id=${franchises_id}&page=${page}&limit=${pageSize}`);
  }

  //!Approve students pagination
  // eslint-disable-next-line @typescript-eslint/naming-convention
  approvedStudents(franchises_id: string, page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/pagination_getApproved_students?franchises_id=${franchises_id}&page=${page}&limit=${pageSize}`);
  }
  //!Disapprove students pagination
  // eslint-disable-next-line @typescript-eslint/naming-convention
  disapprovedStudents(franchises_id: string, page: number, pageSize: number) {
    return this.http.get(this.baseUrl + `students/pagination_getDisapproved_students?franchises_id=${franchises_id}&page=${page}&limit=${pageSize}`);
  }
  //!get single student
  getSingleStudent(data) {
    return this.http.post(this.baseUrl + 'students/get_single_student', data);
  }
  //!search api for new student
  searchNewStudent(data) {
    return this.http.post(this.baseUrl + 'students/search_new_student', data);
  }
  //!search api for approve student
  searchApproveStudent(data) {
    return this.http.post(this.baseUrl + 'students/search_approved_student', data);
  }
  //!search api for disapprove student
  searchDisapproveStudent(data) {
    return this.http.post(this.baseUrl + 'students/search_disapproved_student', data);
  }

  searchTestResult(data) {
    return this.http.post(this.baseUrl + 'students/search_test_result', data);
  }

  uploadFile(data: any) {
    return this.http.post(this.baseUrl + 'upload', data);
  }
}
